export const getCountDown = (time: number) => {
  if (time === 0) {
    return '0分0秒'
  }

  const mins = Math.floor(time / 60)
  const secs = time % 60

  return `${mins}分${secs}秒`
}
