import { Search } from 'auto-libs'
import { Button, Dialog, Layout, Radio, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import http from '../../utils/http'
import { getUserId, setUserId } from '../../utils/storage'
import Banner from './imgs/banner.jpg'
import './style.scss'

interface IState {
  male: string
  female: string
  checkedAgreement: boolean
  popVisible: boolean
}

const PageIndex = () => {
  const navigateHook = useNavigate()
  const cb = Search.get('callback') || ''
  const channel = Search.get('channel') || ''

  const [state, setState] = useSetState<IState>({
    male: '',
    female: '',
    checkedAgreement: false,
    popVisible: false,
  })

  const onInputChange = (e: any) => {
    const value = e.target.value
    const id = e.target.id

    setState({
      [id]: value,
    })
  }

  const onAgreementClick = () => {
    setState({
      checkedAgreement: !state.checkedAgreement,
    })
  }

  const onSubmit = async () => {
    try {
      const { male, female } = state
      if (!male.trim() || !female.trim()) {
        Toast('请先填写双方姓名')
        return
      }

      // if (!state.checkedAgreement) {
      //   Toast('请先同意协议')
      //   return
      // }

      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          bName: male,
          gName: female,
          adType: channel ? channel : 'kuaishou',
          adParams: cb,
          channel: channel,
          productType: '6',
        },
      })

      const { code, body } = result
      if (code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      const { orderId } = body

      localStorage.removeItem('__x_name_test__')

      navigateHook('/pay', { state: { male, female, orderId } })
    } catch (e) {
      Toast('请求失败，请再试')
    }
  }

  const fetchUserId = async () => {
    try {
      const result: any = await http.request({
        url: '/user/getNewUserId',
        method: 'POST',
        data: {},
      })

      const { code, body } = result

      if (code !== '00000000') {
        return
      }

      const { userId } = body

      setUserId(userId)

      statistics(userId)
    } catch (error) {}
  }

  const statistics = async (userId: string) => {
    const params: any = { userId }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  const onOrderClick = () => {
    navigateHook('/order')
  }

  useEffect(() => {
    const userId = getUserId()
    if (userId) {
      statistics(userId)
      return
    }

    fetchUserId()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Header
        title="姓名测试"
        className="header"
        addonAfter={
          <Button hollow mini type="danger" className="order" onClick={onOrderClick}>
            我的订单
          </Button>
        }
      />
      <Layout.Body>
        <div className="banner">
          <div className="marquee">
            <div className="inner">
              本网页数据严格遵循国家法律法规和用户隐私协议对您的信息进行全方位保护
            </div>
          </div>
          <img className="img" src={Banner} alt="" />
        </div>

        <div className="form">
          <div className="input-wrapper">
            <div className="item male">
              <div className="icon" />
              <div className="label">男方姓名</div>
              <div className="input">
                <input
                  id="male"
                  placeholder="请输入姓名"
                  value={state.male}
                  onChange={onInputChange}
                  maxLength={40}
                />
              </div>
            </div>
            <div className="item female">
              <div className="icon" />
              <div className="label">女方姓名</div>
              <div className="input">
                <input
                  id="female"
                  placeholder="请输入姓名"
                  value={state.female}
                  onChange={onInputChange}
                  maxLength={40}
                />
              </div>
            </div>
          </div>

          <div className="agreement" onClick={onAgreementClick}>
            <Radio checked={state.checkedAgreement} className="checkbox" />
            <div className="text">
              您的信息仅用于生成分析报告，
              <a
                onClick={(e) => {
                  setState({
                    popVisible: true,
                  })
                  e.stopPropagation()
                }}
              >
                查看隐私协议
              </a>
              ，提交即视为您已同意《隐私协议》
            </div>
          </div>
        </div>
      </Layout.Body>

      <Layout.Footer className="footer">
        <div className="btns">
          <div className="tip">
            已有<em>159006</em>人解锁姓名测试
          </div>
          <Button className="submit" onClick={onSubmit}>
            开始测试
          </Button>
        </div>
        <div className="company">上海简水网络科技有限公司</div>
        <div className="tip-warning">需付费后方可查看结果，结果纯属娱乐仅供参考</div>
      </Layout.Footer>

      <Dialog
        visible={state.popVisible}
        height="80%"
        title="个人隐私协议"
        onCloseClick={() =>
          setState({
            popVisible: false,
          })
        }
        onMaskClick={() =>
          setState({
            popVisible: false,
          })
        }
      >
        <Dialog.Scroller>
          <div className="content">
            <div className="text">
              <p>尊敬的用户，欢迎阅读本协议：</p>
              <p>
                上海简水网络科技有限公司依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的上海简水网络科技有限公司服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
              </p>
              <p>
                用户在申请上海简水网络科技有限公司服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。
                <strong>
                  若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。
                </strong>
              </p>
              <p>
                保护用户个人信息是上海简水网络科技有限公司的一项基本原则，上海简水网络科技有限公司运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。
                <strong>
                  未经用户许可上海简水网络科技有限公司不会向第三方（上海简水网络科技有限公司控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。
                </strong>
              </p>
              <p>
                在用户发送信息的过程中和本网站收到信息后，
                <strong>
                  本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。
                </strong>
              </p>
              <p>
                此外，您已知悉并同意：
                <strong>
                  在现行法律法规允许的范围内，上海简水网络科技有限公司可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用上海简水网络科技有限公司服务或产品的情况所认为您可能会感兴趣的信息。
                </strong>
              </p>
              <p>
                本网站有权在必要时修改服务条例，
                <strong>
                  本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。
                </strong>
                根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。
              </p>
              <p>上海简水网络科技有限公司保留对本协议的最终解释权。</p>
            </div>
          </div>
        </Dialog.Scroller>
      </Dialog>
    </Layout>
  )
}

export default PageIndex
