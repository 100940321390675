import { Button, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { aliPay, wxPay } from '../../utils/pay'
import { getCountDown } from '../../utils/time'
import ResultLock from './components/result-lock'
import { unlockdata } from './data'
import Banner from './imgs/banner.png'
import './style.scss'

const PagePay = () => {
  const [time, setTime] = useState(60 * 60)
  const [alipayForm, setAlipayForm] = useState<any>(null)
  const locationHook = useLocation()
  const navigateHook = useNavigate()
  const locationState: any = locationHook.state
  const { male, female, orderId } = locationState
  let timer: any = null

  const onBackHome = () => {
    navigateHook('/', { replace: true })
  }

  const countdown = (time: number) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }
      setTime(_time)
      countdown(_time)
    }, 1000)
  }

  const onWechatPay = async () => {
    try {
      Loading()
      const result = await wxPay(orderId)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async () => {
    try {
      Loading()
      const result = await aliPay(orderId)

      setAlipayForm(result)

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  useEffect(() => {
    countdown(60 * 60)
    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay">
      <Layout.Header
        title="姓名测试"
        className="header"
        addonBefore={<div className="back-home" onClick={onBackHome} />}
      />
      <Layout.Body className="body">
        <div className="banner">
          <img src={Banner} />
        </div>

        <div className="names">
          <div className="male man">
            <div className="name">{male}</div>
          </div>
          <div className="heart" />
          <div className="female man">
            <div className="name">{female}</div>
          </div>
        </div>

        <div className="price">
          吉时特价：<em>￥9.9</em>
        </div>

        <div className="countdown">倒计时：{getCountDown(time)}</div>

        <div className="pay-button">
          <Button className="btn wechat" onClick={onWechatPay}>
            <span className="icon" />
            微信支付
          </Button>
          <Button className="btn alipay" onClick={onAlipay}>
            <span className="icon" />
            支付宝支付
          </Button>
        </div>

        <div className="tip">
          已有<em>13860</em>人进行了测试分析，帮助他们找到<em>美满恋爱婚姻，98.9%</em>
          的用户认为测算结果对他们的婚姻生活产生了巨大帮助。
        </div>

        <div className="pay-tip">支付完成后，将为您解锁其余八项重要内容:</div>

        <div className="result-list lock">
          {unlockdata(male, female).map((item, key) => {
            return <ResultLock title={item.title} key={key} data={item.data} />
          })}
        </div>
      </Layout.Body>

      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      />
    </Layout>
  )
}

export default PagePay
