export const unlockdata = (male: string, female: string) => [
  {
    title: `第一：${female}小姐的性格解析`,
  },
  {
    title: `第二：${male}先生的性格解析`,
  },
  {
    title: '第三：配对分析',
    data: ['你们是不是命中注定的⼀对？', '双⽅对待爱情的态度如何？', '哪些因素会⼲扰你们的爱情？'],
  },
  {
    title: '第四：你们的爱情危机',
    data: ['两⼈相处时会出现哪些危机？', '引发危机的原因都有什么？', '如何破解危机，守护爱情？'],
  },
  {
    title: '第五：婚前婚后变化',
    data: [
      '你们是不是命中注定的⼀对？',
      '怎样才让对⽅有被爱的幸福感？',
      '生活中你们是怎样和谐相处？',
    ],
  },
  {
    title: '第六：恋爱婚姻建议',
    data: [
      '你们的恋爱婚姻稳定情况',
      '会屈就于你还是你只有听从的份？',
      '相处之中你们是否更具默契？',
    ],
  },
  {
    title: '第七：感情升温⽅法',
    data: ['专属你们的感情升温秘籍', '提升感情甜蜜度的⽅法', '维系⼆⼈感情稳定的⽅法'],
  },
  {
    title: '第八：（双方优点）魅力相吸',
    data: ['你们的婚姻保质期有多久？', '另一半是否有出轨倾向？', '有什么方法可以趋吉避凶？'],
  },
  {
    title: '第九：子女同步',
    data: ['你们是否会儿孙满堂？', '孩子能让你们开心还是疲惫？', '孩子的到来会让婚姻变得更加稳定'],
  },
]
