import { Search } from 'auto-libs'
import { Alert, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import Result from './components/result'
import { lockdata } from './data'
import Banner from './imgs/banner.png'
import AdTimeMachine from './imgs/timemachine.jpg'
import './style.scss'

export interface IState {
  current: number
  myAnswer?: any
  colorMode?: string
  step: 'one' | 'two' | 'three'
  score: number
  loading: boolean
  errorMsg: string
}

const data = [
  {
    title: '1.你们是怎么认识的：',
    id: 1,
    options: [
      {
        key: 'A',
        value: '同学',
      },
      {
        key: 'B',
        value: '同事',
      },
      {
        key: 'C',
        value: '别人介绍',
      },
      {
        key: 'D',
        value: '其他',
      },
    ],
  },
  {
    title: '2.你们在一起的心情是：',
    id: 2,
    options: [
      {
        key: 'A',
        value: '开心',
      },
      {
        key: 'B',
        value: '平淡',
      },
      {
        key: 'C',
        value: '束缚',
      },
      {
        key: 'D',
        value: '煎熬',
      },
    ],
  },
  {
    title: '3.两个人相处什么最重要：',
    id: 3,
    options: [
      {
        key: 'A',
        value: '外貌',
      },
      {
        key: 'B',
        value: '金钱',
      },
      {
        key: 'C',
        value: '价值观',
      },
      {
        key: 'D',
        value: '性格',
      },
    ],
  },
]

const PageIndex = () => {
  const navigateHook = useNavigate()

  const [resultData, setResultData] = useState<any>(lockdata)
  const [alipayForm, setAlipayForm] = useState<any>(null)
  const [bName, setBName] = useState('')
  const [gName, setGName] = useState('')
  const orderId = Search.get('orderNo') || ''
  console.log(Search.parse())
  const isDontNeedQa = Search.get('isDontNeedQa') === '1' || false
  const backTo = Search.get('backTo')

  const [state, setState] = useSetState<IState>({
    current: 1,
    myAnswer: {},
    step: localStorage['__x_name_test__'] || isDontNeedQa ? 'three' : 'one',
    score: 0,
    loading: false,
    errorMsg: '',
  })

  let timer: any = null
  let payWay = 'ALIPAY'
  let alertCloseHandler: any = null

  const onBackHome = () => {
    if (backTo === 'order') {
      navigateHook('/order', { replace: true })
    } else {
      navigateHook('/', { replace: true })
    }
  }

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: 'result/queryXmpd2',
        method: 'POST',
        data: {
          orderId,
        },
      })

      const { body } = result
      const { bName, gName, score, bxg, gxg, bsm, gsm, aqwj, aqjy } = body
      const resultList = [bxg, gxg, bsm, gsm, aqwj, aqjy]

      if (bName && gName) {
        const d = lockdata(bName, gName).map((item: any, index: number) => {
          item.data = resultList[index]
          return item
        })

        setResultData(d)
        setBName(bName)
        setGName(gName)
        setState({ score })
      }
    } catch (e: any) {
      setState({ errorMsg: e.message || '系统异常，请重试' })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId)

    setAlipayForm(result)

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    const [close] = Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
        // {
        //   name: '关闭',
        //   type: 'lighter',
        //   onClick: () => {
        //     close()
        //   },
        // },
      ],
    })

    alertCloseHandler = close
  }

  const onAnswerClick = async (answer: string, id: number) => {
    const answerArr: any = state.myAnswer
    answerArr[id] = answer

    setState({
      myAnswer: answerArr,
    })

    await sleep(500)

    if (data.length > 1 && state.current < data.length) {
      onBtnHanlder('next')
    } else {
      onBtnHanlder('finished')
    }
  }

  const sleep = (time: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('done')
      }, time)
    })
  }

  const onBtnHanlder = (type: 'prev' | 'next' | 'finished') => {
    try {
      if (type === 'prev') {
        setState({
          current: state.current - 1,
        })
      } else if (type === 'next') {
        checkAnswer()
        setState({
          current: state.current + 1,
        })
      } else {
        checkAnswer()
        // 展示结果
        localStorage['__x_name_test__'] = 'done'
        setState({
          step: 'two',
        })

        // 2s展示结果
        timer = setTimeout(() => {
          setState({
            step: 'three',
          })
        }, 3000)
      }
    } catch (error) {
      Toast((error as any).message)
    }
  }

  const checkAnswer = () => {
    const { current } = state
    const currentQuestion: any = data[current - 1]
    const id = currentQuestion.id
    const answerArr: any = state.myAnswer
    if (!answerArr[id]) {
      throw new Error('请选择一个答案')
    }
  }

  useEffect(() => {
    fetchMatchResult()

    return () => {
      timer && clearTimeout(timer)

      alertCloseHandler && alertCloseHandler()
    }
  }, [])

  const totalLength = data.length
  const currentQuestion: any = data[state.current - 1]

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Header
        title="姓名配对"
        className="header"
        addonBefore={<div className="back-home" onClick={onBackHome} />}
      />
      <Layout.Body className="body" loading={state.loading} errorInfo={state.errorMsg}>
        {state.step === 'one' && (
          <div className="step step1">
            <div className="tip">
              为了便于获取更精准的测试信息
              <br />
              请认真填写下面三道题
            </div>
            <div className="qwrapper">
              <div className="number-list">
                第{state.current}/{totalLength}题
              </div>
              <div className="qcontent">
                <div className="title">{currentQuestion.title}</div>
                <div className="answers">
                  {currentQuestion.options.map((item: any) => {
                    return (
                      <div
                        key={item.key}
                        className={`item ${
                          item.key === state.myAnswer[currentQuestion.id] ? 'checked' : ''
                        }`}
                        onClick={() => onAnswerClick(item.key, currentQuestion.id)}
                      >
                        {item.key}.{item.value}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {state.step === 'two' && (
          <div className="step step2">
            <div className="t">
              <div className="title">恭喜完成答题</div>
              <div className="subtitle">FINAL REPORT</div>
            </div>

            <div className="body">
              <div className="content">
                <div className="secret">数据分析中...</div>
                <div className="circle" />
              </div>
            </div>
          </div>
        )}

        {state.step === 'three' && (
          <div className="step step3">
            <div className="banner">
              <img src={Banner} />
            </div>

            <div className="names">
              <div className="male man">
                <div className="name">{bName}</div>
              </div>
              <div className="heart" />
              <div className="female man">
                <div className="name">{gName}</div>
              </div>
            </div>

            <div className="pay-tip">
              <em>{bName}</em>与<em>{gName}</em>合婚结果
            </div>

            {state.score > 0 && (
              <div className="score">
                配对评分: <em>{state.score}</em>
              </div>
            )}

            <div className="result-list lock">
              {resultData.map((item: any, key: number) => {
                return <Result title={item.title} key={key} data={item.data} />
              })}
            </div>

            <div className="ad">
              <a href="https://sgcs.ijianshui.com/?channel=umtr$50">
                <img src={AdTimeMachine} />
                <h1>测测你变年轻或变老的样子</h1>
              </a>
            </div>
          </div>
        )}
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      />
    </Layout>
  )
}

export default PageIndex
